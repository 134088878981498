<template>
  <div>Dashboard Comming Soon!</div>
</template>

<script>
export default {
  mounted() {
  },
}
</script>

<style>
</style>
